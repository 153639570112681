module.exports = {
    'FIRST NAME': '',
    'LAST NAME': '',
    'DATE OF BIRTH': '',
    'GENDER': '',
    'HEALTH CARD NUMBER': '',
    'UNIT NUMBER': '',
    'STREET NUMBER': '',
    'STREET NAME': '',
    'PO BOX': '',
    'CITY\/TOWN': '',
    'PROVINCE': '',
    'POSTAL CODE': '',
    'TELEPHONE NUMBER': '',
    'EMAIL ADDRESS': '',
    'PRIMARY FIRST NAME': '',
    'PRIMARY LAST NAME': '',
    'PROVIDER DESIGNATION': '',
    'PRIMARY PHONE #': '',
    'PRIMARY FAX #': '',
    'PRIMARY EMAIL ADDRESS': '',
    'HEALTHCARE PROVIDERS WITH WHO TO COMMUNICATE': '',
    'CAREGIVER FIRST NAME': '',
    'CAREGIVER LAST NAME': '',
    'CAREGIVER PHONE #': '',
    'CARGIVER EMAIL ADDRESS': '',
    'CAREGIVER NOTES': '',
    'LIST OF ALLERGIES': '',
    'CHECKBOX NO KNOWN DRUG ALLERGIES': '',
    'TOBACCO': '',
    'TOBACCO NO': '',
    'TOBACCO YES': '',
    'NUMBER OF CIGS': '',
    'SMOKING CESSATION STATUS': '',
    'SMOKING CESSATION STATUS DROPDOWN': '',
    'Recreational Drug Use': '',
    'Recreational Drug Use no': '',
    'Recreational Drug Use yes': '',
    'Recreational Drug Use Notes': '',
    'ALCOHOL USE': '',
    'ALCOHOL USE NO': '',
    'ALCOHOL USE YES': '',
    'Alcohol use Dropdown': '',
    'Exercise Regimen': '',
    'MINUTES PER WEEK': '',
    'EXERCISE REGIMEN NOTES': '',
    'LIFESTYLE INFORMATION OTHER': '',
    'LIFESTYLE INFORMATION OTHER NOTES': '',
    'OCCUPATION': '',
    'PRIVATE INSURANCE YES': '',
    'PRIVATE INSURANCE NO': '',
    'LIFESTYLE RECCOMENDATIONS DROPDOWN': '',
    'LIFESTYLE RECCOMENDATIONS DROPDOWN1': '',
    'LIFESTYLE RECCOMENDATIONS DROPDOWN2': '',
    'LIFESTYLE RECCOMENDATIONS': '',
    'WEIGHT CHECK BOX': '',
    'WEIGHT NOTE': '',
    'HEIGHT NOTE': '',
    'WAIST CIRCUMFERENCE': '',
    'BMI': '',
    'COGNITIVE FUNCTION/LEARNING IMPAIRMENTS': '',
    'COGNITIVE FUNCTION/LEARNING IMPAIRMENTS NOTES': '',
    'MEDCHECK FOR DIABETES AVAILABLE': '',
    'DTP IDENTIFIED AND RESOLVED': '',
    'DTP SEE ATTACHED DRUG NOTES': '',
    'DTP IDENTIFIED AND RESOLVED NOTES': '',
    'DIETARY CONCERNS NOTES': '',
    'LIFESTYLE INFORMATION OTHER': '',
    'LIFESTYLE INFORMATION OTHER NOTES': '',
    'STAGE OF READINESS FOR CHANGE': '',
    'STAGE OF READINESS FOR CHANGE NOTES': '',
    'SUMMARY PATIENT GOALS 1.0': '',
    'SUMMARY PATIENT GOALS 1.1': '',
    'SUMMARY PATIENT GOALS 1.2': '',
    'PATIENT GOALS NOTES': '',
    'INSULIN USE YES': '',
    'INSULIN USE NO': '',
    'HYPOGLYCEMIC YES': '',
    'HYPOGLYCEMIC NO': '',
    'COMMENTS ON DIABETIC DRUG THERAPY 1': '',
    'COMMENTS ON DIABETIC DRUG THERAPY 2': '',
    'COMMENTS ON DIABETIC DRUG THERAPY 3': '',
    'PHARMACISTS COMMENTS ON DIABETIC DRUG THERAPY': '',
    'HBA1C': '',
    'HBA1C NOTES': '',
    'BLOOD PRESSURE AVERAGE': '',
    'BLOOD PRESSURE NOTE': '',
    'FASTING PLASMA GLUCOSE': '',
    'FPG NOTE': '',
    'TOTAL CHOLESTEROL': '',
    'HDL RATIO': '',
    'LDL': '',
    'HDL': '',
    'TG': '',
    'MANAGEMENT OF MEDICATIONS': '',
    'REVIEWED MEDS': '',
    'COMPLIANCE PACKAGING': '',
    'SNAP CAPS': '',
    'FOOT CARE DISCUSSION': '',
    'FOOT CARE DOING WELL': '',
    'FOOT CARE ULCERATION': '',
    'FOOT CARE PAIN': '',
    'FOOT CARE NUMBNESS': '',
    'FOOT CARE REFER TO PHYSICAN': '',
    'SCRENNING FOR DN': '',
    'DN DOING WELL': '',
    'DN REFER TO PHYSICAN': '',
    'FOOT CONDITIONS ULCERS': '',
    'FOOT CONDITIONS DOING WELL': '',
    'FOOT CONDITIONS REFER': '',
    'PROPER SHOE FIT': '',
    'SHOE FIT DOING WELL': '',
    'SHOE RECOMMENDATION': '',
    'BLOOD PRESSURE MONITORING': '',
    'BP MONITORED REGULARLY': '',
    'BP ADVISED TO MONITOR': '',
    'CV AND OTHER RISK FACTORS': '',
    'UNCONTROLLED CHOLESTEROL LEVELS': '',
    'UNCONTROLLED GLUCOSE LEVELS': '',
    'OVERWEIGHT CHECKBOX': '',
    'MENTAL HEALTH ASSESSMENT': '',
    'MENTAL HEALTH DOING WELL': '',
    'MENTAL HEALTH REFER TO PHYSICAN': '',
    'ERRECTILE DYFUNCTION': '',
    'ERRECTILE DYFUNCTION DOING WELL': '',
    'ERRECTILE DYFUNCTION REFERRAL': '',
    'LIFESTYLE CHECK BOX': '',
    'LIFESTYLE DIET': '',
    'DIET WILL IMPROVE': '',
    'DIET WILL MAINTAIN': '',
    'DIET SODIUM RESTIRCTED': '',
    'DIET DROPDOWN': '',
    'LIFESTYLE STRESS REDUCTION': '',
    'Lifestyle Meditation': '',
    'LIFESTYLE RELXATAION TECHNIQUES': '',
    'REFER TO PHYSICAIN STRESS REDUCTION': '',
    'LIFESTYLE EXCERCISE': '',
    'EXERCISE WILL INCREASE': '',
    'EXERCISE WILL MAINTAIN': '',
    'EXERCISE UNABLE': '',
    'EYE HEALTH AND REMINDER': '',
    'EYE HEALTH WELL': '',
    'EYE HEALTH FOLLOW UP': '',
    'DENTAL HYGIENE': '',
    'DENTAL HYGINE DOING WELL': '',
    'DENTAL FOLLOW UP': '',
    IMMUNIZATIONS: '',
    'FLU VACCINE': '',
    'FLU YES': '',
    'FLU DR REFERRAL': '',
    'PNEUMOCOCCAL VACCINE': '',
    'PENUMOCOCCAL YES': '',
    'PNEUMOCOCCAL PHYSICIAN': '',
    'OTHER IMMUNIZATIONS': '',
    'SHINGLES': '',
    'VACCINE OTHER NOTE': '',
    'DRIVING GUIDELINES': '',
    'DRIVING NA': '',
    'DRIVING DOING WELL': '',
    'DRIVING FOLLOW UP': '',
    'TRAVELLING WITH DIABETES CHECKBOX': '',
    'TRAVELLING DICUSSED': '',
    'SELF MONITORING METER TRAINING AWARE CHECKBOX': '',
    'SELF MONITORING METER TRAINING AWARE': '',
    'SELF MONITORING METER TRAINING REVIEWED': '',
    'BLOOD SUGAR TESTING FREQUENCY': '',
    'SELF MONITORING FREQ 2-3': '',
    'SELF MONITORING FREQUECNY DAILY': '',
    'SELF MONITORING FREQ MULTIPLE': '',
    'SELF MONITORING FREQ PHYSICIAN': '',
    'RECORDING OF RESULTS': '',
    'SELF MONITORING LOGBOOK': '',
    'SELF MONITORING GLUCOSE MACHINE': '',
    'IDENTIFICATION OF PATTERNS': '',
    'SELF MONITORING ELEVATED AM': '',
    'SELF MONITORING ELEVATED PM': '',
    'SELF MONITORING LVLS CONTROOLED': '',
    'HYPOGLYCEMIA': '',
    'SELF MONITORING HYPO COUNCEL': '',
    'SELF MONITORING HYPO DEXTROS TABLETS': '',
    'PROPER MED HANDLING': '',
    'SELF MONITORING SUPPLIES REVIEWED': '',
    'DISPOSAL': '',
    'DISPOSAL REVIEWED': '',
    'SELF MONITORING TRAINING ADVISED': '',
    'SELF MONITORING TRAINING NA': '',
    'SICK DAYS': '',
    'SICK DAYS REVIEWED': '',
    'INDIVIDUALIZED BG TARGETS': '',
    'FPG': '',
    'INDIVIDUALZIED FPG': '',
    'PPG': '',
    'INDIVIDUALZIED PPG': '',
    'SELF MONITORING OTHER': '',
    'SELF MONITORING OTHER NOTE': '',
    'INSULIN TYPES': '',
    'INSULIN TYPE 1': '',
    'INSULIN TYPE 2': '',
    'INSULIN TYPE 3': '',
    'PEN HANDLING': '',
    'TRAINING PEN HANDLING': '',
    'SITE PREPARTAION AND INSPECTION': '',
    'TRAINING SITE PREPARATION': '',
    'INJECTION TRAINING': '',
    'TRAINING GLP-1': '',
    'PROPER INJECTION TECHNIQUE': '',
    'TRAINING SITE ROTATION TECHNIQUE': '',
    'PATIENT DEMONSTRATED USE': '',
    'TRAINING DEMOSTRATED USE YES': '',
    'TRAINING DEMONSTRATED USE NO': '',
    'DOSAGE ADJUSTMENTS': '',
    'TRAINING DOSAGE ADJUMENTS NOT RQUIRED': '',
    'TRAINING DOSAGE ADJ PHYSICAN': '',
    'MISSED OR SKIPPED DOSES': '',
    'TRAINING MISSED OR SKIPPED INJECTIONS': '',
    'REVIEW OF CARBOHYDRATE COUNTING': '',
    'EMERGENCY AND SICK DAY MANAGMENT': '',
    'TRAINING EMERGENCY AND SICK DAY': '',
    'TRAINING EMERGENCY NA': '',
    'ALCOHOL/DRUGS/TOBACO': '',
    'TRAINING ALOCHOL REVIWED': '',
    'ALCHOL DRUGS NA': '',
    'UPDATED INSULIN REGIMEN': '',
    'INSULIN TYPE UPDATED 1': '',
    'INSULIN TYPE UPDATED 2': '',
    'INSULIN UNITS AM': '',
    'INSULIN UNITS NOON': '',
    'INSULIN UNITS SUPPER': '',
    'INSULIN UNITS HS': '',
    'INSULIN UNITS OTHER': '',
    'SPECIALTY TRAINING OTHER': '',
    'SPECIALTY TRAINING OTHER NOTE': '',
    'OTHER COMPLICATIONS DISCUSSED': '',
    'OTHER COMPLICATIONS DISCUSSED NOTES': '',
    'RESOURCES DIABETES PASSPORT': '',
    'RESOURCES PASSPORT': '',
    'RESOURCES SICK DAYS': '',
    'RESOURCES SICK DAY': '',
    'RESOURCES AFTER HOURS SUPPORT': '',
    'RESOURCES AFTER HOURS': '',
    'RESOURCES DIRECTORY OF LOCAL COMMUNITY RESOURCES': '',
    'RESOURCES LOCAL RESOURCES': '',
    'RESOURCES OTHER MATEIRALS': '',
    'RESOURCES OTHER MATERIAL': '',
    'RESOURCE INFO TO BE ADDED': '',
    '(SECTION HAS A LOT OF OVERLAP)': '',
    'REFERRALS ENDOCRINOLOGIST': '',
    'REFERRAL ENDO': '',
    'REFERRALS PRIMARY CARE PHYSICIAN': '',
    'REFERRAL PRIMARY CARE': '',
    'REFERRALS DIETITIAN': '',
    'REFERRAL DIETITIAN': '',
    'REFERRALS NURSE': '',
    'REFERRAL NURSE': '',
    'REFERRALS FAMILY PHARMACIST': '',
    'REFERRAL PHARMACIST': '',
    'REFERRALS OTHER': '',
    'REFERRAL  OTHER': '',
    'REFERRALS FOLLOW UP DATE': '',
    'FOLLOW UP DATE': '',
    'REFERRAL PURPOSE NOTES': '',
    'REFERRAL INFO TO BE ADDED': '',
    '(HAS OVERLAP)': '',
    'SUMMARY OF DISCUSSION 1': '',
    'SUMMARY OF DISCUSSION 2': '',
    'SUMMARY OF DISCUSSION 3': '',
    'WHAT I WILL DO TO GET THERE26.0': '',
    'WHAT I WILL DO TO GET THERE26.1': '',
    'WHAT I WILL DO TO GET THERE26.2': '',
    'WHAT I WILL DO TO GET THERE NOTES': '',
    'MEDSCHECK ANNUAL CHECKBOX ACKNOWLEDGMENET': '',
    'MEDSCHECK FOLLOW-UP CHECKBOX ACKNOWLEDGMENET': '',
    'MEDSCHECK FOR DIABETES CHECKBOX ACKNOWLEDGMENET': '',
    'DIABETES EDUCATION FOLLOW-UPMEDSCHECK CHECKBOX ACKNOWLEDGMENET': '',
    'MEDSCHECK AT HOME CHECKBOX ACKNOWLEDGMENET': '',
    'MEDSCHECK SERVICE PROVIDED': '',
    'LOCATION': '',
    'MEDSCHECK AT PHARMACY': '',
    'MEDSCHECK AT PATIENT HOME': '',
    'NEW PATIENT TO PHARMACY': '',
    'ANNUAL REVIEW': '',
    'PATIENT REQUEST': '',
    'PHYSICAIN REFERRAL': '',
    'MEDICATION CHANGES': '',
    'CLINICAL NEED FOR SERVICE COMMENTS': '',
    'THREE OR MORE MEDICATIONS': '',
    'MULTIPLE CONDITIONS': '',
    'ONE OR MORE NON RX MEDS': '',
    'ONE OR MORE NHP': '',
    'UNDADDRESSED SYMPTOMS': '',
    'POTENTIAL DRPS': '',
    'MULTIPLE DOCTORS': '',
    'ISSUES EARLY OR LATE REFILLS': '',
    'NON-ADHERENCE': '',
    'PATIENT CONFUSED': '',
    'MEDS WITH LAB MONITORING': '',
    'ABNORMAL LAB RESULTS': '',
    'PLANNED ADMISSION': '',
    'DISCHARGE FROM HOSPITAL TO COMMUNITY': '',
    'COMPLIANCE PACKAGING': '',
    'RENAL FUNCTION': '',
    'LIVER FUNCTION': '',
    'PATIENT CHARACTERISTICS OTHER': '',
    'PATIENT CHARACTERISTICS OTHER NOTE': '',
    'CONSULTED PHARMACY PROFILE': '',
    'CONSULTED PHYSICIAN / NURSE PRACTITIONER': '',
    'CONSULTED PATIENT': '',
    'CONSULTED CAREGIVER / AGENT': '',
    'CONSULTED ANOTHER PHARMACY': '',
    'CONSULTED MEDICATION PACKAGES': '',
    'CONSULTED LABORATORY TEST VALUES': '',
    'CONSULTED ELECTRONIC HEALTH RECROD': '',
    'CONSULTED HOSPITAL / OTHER FACILITY': '',
    'CONSULTED OTHER': '',
    'SOURCES OTHER NOTES': '',
    'PHARMACY NAME': '',
    'PHARMACY UNIT NUMBER': '',
    'PHARMACY STREET NUMBER': '',
    'PHARMACY STREET NAME': '',
    'PHARMACY PO BOX': '',
    'PHARMACY CITY': '',
    'PHARMACY PROVINCE': '',
    'PHARMACY POSTAL CODE': '',
    'PHARMACY TELEPHONE NUMBER': '',
    'PHARMACY FAX NUMBER': '',
    'PHARMACY EMAIL ADDRESS': '',
    'MEDSCHECK PHARMACIST FIRST NAME': '',
    'MEDSCHECK PHARMACIST LAST NAME': '',
    'OCP #': '',
    'ADD A CHECKBOX TO EACH MED TO SAYS DISCONTINUED AND IF THE CHECKBOX IS SELECTED THEN LIST THE MEDS IN THE TEXTBOX BELOW AND REMOVE FROM BLISTER PACK SHEETS/GRIDS, ETC.': '',
    'CLINICALLY RELEVANT DISCONTINUED MEDICATIONS': '',
    'THERAPEUTIC ISSUE': '',
    'THERAPEUTIC ISSUE SUGGESTED THERAPY': '',
    'THERAPEUTIC ISSUE ACTION TAKEN': '',
    'MED 1': '',
    'COMMENTS FOR MEDSCHECK RECORD 1': '',
    'ASKED ABOUT RX MEDICATIONS': '',
    'MEDS REMOVED FROM HOME': '',
    'REMOVED FROM HOME': '',
    'ASKED ABOUT OTC PRODUCTS': '',
    'ASKED ABOUT HERBAL PRODUCTS': '',
    'ASKED ABOUT UNCOMMON DOSAGE FORMS': '',
    'ASKED ABOUT ANTIOBITOCS IN THE LAST 3 MONTHS': '',
    'REFERECNED NOTES': '',
    'CIRCLE OF CARE': '',
    'ANTICIPATED DATE OF COMPLETION': '',
    'CLINICALLY RELVANT INFORMATION DOCUMNETED': '',
    'COMPLETENESS OTHER': '',
    'COMPLETENESS OTHER NOTES': ''
};